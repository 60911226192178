/**
 * 结算管理api
 */

export default {
  /**
   * 订单列表
   */
  // 列表
  getSaleOrderList: '/settlement_info/select_online_orders',
  // 详情
  getSaleOrderInfo: '/settlement_info/select_online_orders_info',

  /* 订单------- */
  // 线上线下列表   订单来源：1线下；2线上商城(必填)
  ordersList: "/settlement_info/select_online_orders",
  // 线上订单导出
  ordersListExport: "/shop_order/select_online_orders_export",
  // 取消订单
  cancelOrders: "/shop_order/cancel_order",
  // 接单
  takingOrders: "/shop_order/taking_order_button",
  // 到店自取
  ordersPickup: "/shop_order/pick_up_to_shop",
  // 退款
  refundOrders: "/shop_order/refund_order",
  // 线上线下列表详情
  ordersDet: "/settlement_info/select_online_orders_info",
  // 售后订单列表
  ordersRefundList: "/settlement_info/select",
  // 售后订单详情
  ordersRefundDet: "/settlement_info/get",
  // 售后订单拒绝退款+同意退款处理
  ordersRefundUpdate: "/shop_order_refund/update_status",
  /* 订单------- */
  /**
   * 商超明细 供应商明细
   */
  // // 列表
  // getSettlementInfoList: '/settlement_info/list',
  // 列表
  getSettlementInfoList: "/settlement_info/settlement_order_list",
  // 列表-查看详情
  getSettlementInfoDetail: "/settlement_info/settlement_order_info",

  // 修改分账状态
  changeBillStatus: '/settlement_info/update_bill_status',

  /**
   * 骑手清分
   */

  // 列表
  getRiderInfoList: '/rider_settlement/list',
  // 骑手详情
  getRiderInfoDetail: '/riderInfo/pageListDetails',

  // 提现申请
  getRiderExtractApplyList: '/riderExtractApply/pageList',
  // 申请详情
  getRiderExtractApplyDetail: '/riderExtractApply/pageListDetails',
  // 审核通过
  passRiderExtractApply: '/riderExtractApply/pass',
  // 审核不通过
  notpassRiderExtractApply: '/riderExtractApply/not_pass',

  /**
   * 商超清算
   */

  // 商超提现申请列表
  getPageList: '/shopExtractApply/pageList',
  // 提现申请>查看{id}
  getPageListDetails: '/shopExtractApply/pageListDetails/',
  // 审核
  supermarketsAuditing: '/shopExtractApply/auditing',
  /**
   * 供应商清分
   */

  // 供应商申请列表
  setSupplierList: '/supplierExtractApply/pageList',
  // 供应商提现申请>查看{id}
  setSupplierListDet: '/shopExtractApply/pageListDetails',
  // 供应商提现申请审核
  setSupplierListDetAuditing: '/supplierExtractApply/auditing',
};

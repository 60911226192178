/**
 * 商超管理api
 */
export default {
  // 商超管理
  shopInfoPage: '/shopInfo/shopInfoPage',
  // 商超查询
  shopInfo: '/shopInfo/shopInfo',
  //审核
  auditing: '/shopInfo/auditing',
  // 启动
  shopChange: '/shopInfo/change',
  // 删除轮播图
  delBanner: '/adCarousel/delete',
  // 轮播图列表
  pageList: '/adCarousel/pageList',
  // 新增轮播图
  addBanner: '/adCarousel/save',
  // 查询轮播图详情{ id }
  select_info: '/adCarousel/select_info/',
  // 修改轮播图
  updateBanner: '/adCarousel/update',
  //  商超统计-列表
  getShopTotalPageList: '/shopInfo/shopTotalPageList',
  //  商超统计-折线图
  getShopTotalStatList: '/shopInfo/shopTotalStatList',
};

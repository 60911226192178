// 技术服务费
export default [
  {
    path: '/technicalServiceFee',
    meta: { title: '技术服务费' },
    component: () => import('../views/technicalServiceFee/index.vue'),
    children: [
      {
        path: '/technicalServiceFee/supplierFee',
        meta: { title: '供应商服务费', isMenu: true },
        component: () => import('../views/technicalServiceFee/supplierFee.vue'),
      },
      // {
      //   path: '/technicalServiceFee/shopFee',
      //   meta: { title: '商超服务费', isMenu: true },
      //   component: () => import('../views/technicalServiceFee/shopFee.vue'),
      // },
      // {
      //   path: '/technicalServiceFee/detail/shopFeeDetail',
      //   meta: { title: '商超服务费详情' },
      //   component: () => import('../views/technicalServiceFee/detail/shopFeeDetail.vue'),
      // },
      {
        path: '/technicalServiceFee/detail/supplierFeeDetail',
        meta: { title: '供应商服务费详情' },
        component: () => import('../views/technicalServiceFee/detail/supplierFeeDetail.vue'),
      },
    ],
  },
];
import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"
import system from "./system"
import supplier from "./supplier"
import productMgt from "./productMgt"
import school from "./school"
import supermarket from "./supermarket"
import expert from "./expert"
import gateway from "./gateway"
import customerService from "./customerService"
import contract from "./contract"
import settlement from "./settlement"
import technicalServiceFee from "./technicalServiceFee"
import business from "./business"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    meta: { title: "校园云采-管理后台" },
    component: () => import("../views/Main.vue"),
    children: [...system, ...supplier, ...productMgt, ...school, ...supermarket, ...expert, ...gateway, ...customerService, ...contract, ...settlement, ...technicalServiceFee, ...business],
  },
  {
    path: "/login",
    name: "login",
    meta: { title: "管理后台-登录" },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    meta: { title: "忘记密码" },
    component: () => import("../views/forgotPassword.vue"),
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "服务器拒绝访问或页面不存在" },
    component: () => import("../views/404.vue"),
  },
]

const router = new VueRouter({
  routes,
})

const originaPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originaPush.call(this, location).catch(err => err)
}

router.beforeEach((to, form, next) => {
  const token = store.state.token
  if (localStorage.getItem("tendersLabel") && to.path != "/biddingManagement/bidList" && to.path != "/biddingManagement/detail/bidEvaluationTable") {
    localStorage.removeItem("tendersLabel")
  }
  if (to.path == "/register" || to.path == "/forgotPassword") {
    next()
  } else if (!token && to.path !== "/login") {
    store.dispatch("logout")
    next("/login")
  } else if (token && to.path == "/login") {
    store.dispatch("logout")
    localStorage.clear()
    next()
  } else {
    next()
  }
})

router.afterEach(to => {
  if (to.meta.title) document.title = to.meta.title
})

export default router

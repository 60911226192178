/**
 * 供应商信息api
 */
export default {
  supplierInfoDetails: "/supplierInfo/details", // 查看详情{supplierId}
  supplierInfoEnable: "/supplierInfo/enable", // 停用/启用
  supplierInfoExamine: "/supplierInfo/examine", // 审核
  supplierInfoPageList: "/supplierInfo/pageList", // 供应商列表/供应商审核
  supplierInfoRegister: "/supplierInfo/register", // 注册
  supplierInfoSelectApply: "/supplierInfo/selectApply", // 查询申请
  supplierInfoSelectSupplierName: "/supplierInfo/selectSupplierName", // 查询供应商是否存在{supplierName}
  portalBiddingEligibleList: "/biddingReview/portalBiddingEligibleList", // 总后台应标资格列表

  getSupplierTotalPageList: "/supplierInfo/supplierTotalPageList", //  统计-列表
  getSupplierTotalStatList: "/supplierInfo/supplierTotalStatList", //  统计-折线图

  deleteSupplierDistrict: "/supplierInfo/deleteSupplierDistrict", // 供应商管理>供应商列表>供货关系-删除
  insertSupplierDistrict: "/supplierInfo/insertSupplierDistrict", // 供应商管理>供应商列表>供货关系-新建
  selectShopInfoListByName: "/supplierInfo/selectShopInfoListByName", // 供应商管理>供应商列表>供货关系-查看商超
  selectSupplierDistrictList: "/supplierInfo/selectSupplierDistrictList", // 供应商管理>供应商列表>供货关系-列表

  // supplierBiddingProductPageList: "/supplierInfo/supplierBiddingProductPageList", // 供应商管理>中标供应商列表
  supplierBiddingProductDetail: "/supplierInfo/supplierBiddingProductDetail", // 供应商管理>中标供应商列表 new
  supplierBiddingProductUpAndDown: "/supplierInfo/supplierBiddingProductUpAndDown", // 供应商管理>中标供应商列表->上下架
  updateSupplierSupplyShopStatus: "/supplierInfo/updateSupplierSupplyShopStatus", // 供应商管理>供应商列表>供货关系-查看商超-更改供货状态
}

// 饭堂管理
export default [
  {
    path: '/supermarket',
    meta: { title: '饭堂管理' },
    component: () => import('../views/supermarket/index.vue'),
    // redirect: '/supermarket/list',
    children: [
      {
        path: '/supermarket/list',
        meta: { title: '饭堂列表', isMenu: true },
        component: () => import('../views/supermarket/list.vue'),
      },
      {
        path: '/supermarket/examine',
        meta: { title: '饭堂审核', isMenu: true },
        component: () => import('../views/supermarket/examine.vue'),
      },
      {
        path: '/supermarket/detail/add',
        meta: { title: '添加饭堂' },
        component: () => import('../views/supermarket/detail/add.vue'),
      },
      {
        path: '/supermarket/detail/edit',
        meta: { title: '修改饭堂信息' },
        component: () => import('../views/supermarket/detail/edit.vue'),
      },
      {
        path: '/supermarket/detail/info',
        meta: { title: '查看饭堂信息' },
        component: () => import('../views/supermarket/detail/info.vue'),
      },
      // {
      //   path: '/supermarket/banner',
      //   meta: { title: '商城轮播图', isMenu: true },
      //   component: () => import('../views/supermarket/banner.vue'),
      // },
      // {
      //   path: '/supermarket/statistics',
      //   meta: { title: '饭堂统计', isMenu: true },
      //   component: () => import('../views/supermarket/statistics.vue'),
      // },
      // {
      //   path: '/supermarket/inventoryReview',
      //   meta: { title: '库存审核', isMenu: true },
      //   component: () => import('../views/supermarket/inventoryReview.vue'),
      // },
      // {
      //   path: '/supermarket/detail/inventoryDetail',
      //   meta: { title: '库存审核查看'},
      //   component: () => import('../views/supermarket/detail/inventoryDetail.vue'),
      // },
    ],
  },
];

// 结算管理
export default [
  {
    path: '/settlement',
    meta: { title: '结算管理' },
    component: () => import('../views/settlement/index.vue'),
    // redirect: '/settlement/order',
    children: [
      // {
      //   path: '/settlement/order',
      //   meta: { title: '订单列表', isMenu: true },
      // },

      // {
      //   path: '/settlement/order/onlineOrder',
      //   name: 'onlineOrder',
      //   meta: { title: '线上订单', isMenu: true },
      //   component: () => import('../views/settlement/onlineOrder.vue'),
      // },
      // {
      //   path: '/settlement/detail/distributionDet',
      //   meta: { title: '配送订单详情' },
      //   name: 'distributionDet',
      //   component: () => import('../views/settlement/detail/distributionDet.vue'),
      // },
      // {
      //   path: '/settlement/detail/selfTakeDet',
      //   meta: { title: '自提订单详情' },
      //   name: 'selfTakeDet',
      //   component: () => import('../views/settlement/detail/selfTakeDet.vue'),
      // },
      // {
      //   path: '/settlement/order/offlineOrder',
      //   meta: { title: '线下订单', isMenu: true },
      //   component: () => import('../views/settlement/offlineOrder.vue'),
      // },
      // {
      //   path: '/settlement/detail/offlineOrderDet',
      //   meta: { title: '线下订单详情' },
      //   name: 'offlineOrderDet',
      //   component: () => import('../views/settlement/detail/offlineOrderDet.vue'),
      // },
      // {
      //   path: '/settlement/order/afterSaleOrder',
      //   meta: { title: '售后订单', isMenu: true },
      //   component: () => import('../views/settlement/afterSaleOrder.vue'),
      // },
      // {
      //   path: '/settlement/detail/afterSaleOrderDet',
      //   meta: { title: '售后订单详情' },
      //   name: 'afterSaleOrderDet',
      //   component: () => import('../views/settlement/detail/afterSaleOrderDet.vue'),
      // },

      // {
      //   path: '/settlement/shopDetail',
      //   meta: { title: '商超明细', isMenu: true },
      //   component: () => import('../views/settlement/shopDetail.vue'),
      // },
      {
        path: '/settlement/supplier',
        meta: { title: '供应商清分', isMenu: true },
      },
      {
        path: '/settlement/supplier/supplierDetail',
        meta: { title: '供应商明细', isMenu: true },
        component: () => import('../views/settlement/supplierDetail.vue'),
      },
      {
        path: '/settlement/detail/supplierDetailVo',
        meta: { title: '供应商明细详情' },
        component: () => import('../views/settlement/detail/supplierDetailVo.vue'),
      },
      {
        path: '/settlement/supplier/supplierWithdrawal',
        meta: { title: '供应商提现申请', isMenu: true },
        component: () => import('../views/settlement/supplierWithdrawal.vue'),
      },
      {
        path: '/settlement/detail/supplierWithDet',
        meta: { title: '供应商提现申请详情' },
        component: () => import('../views/settlement/detail/supplierWithDet.vue'),
      },


      // {
      //   path: '/settlement/cycler',
      //   meta: { title: '骑手清分', isMenu: true },
      // },
      // {
      //   path: '/settlement/cycler/cyclerDefiniteDetailed',
      //   meta: { title: '骑手明细', isMenu: true },
      //   component: () => import('../views/settlement/cyclerDefiniteDetailed.vue'),
      // },
      // {
      //   path: '/settlement/cycler/withdrawalApply',
      //   meta: { title: '提现申请', isMenu: true },
      //   component: () => import('../views/settlement/withdrawalApply.vue'),
      // },


      // {
      //   path: '/settlement/detail/orderDetail',
      //   meta: { title: '订单信息' },
      //   component: () => import('../views/settlement/detail/orderDetail.vue'),
      // },
      // {
      //   path: '/settlement/detail/applyDetail',
      //   meta: { title: '申请详情' },
      //   component: () => import('../views/settlement/detail/applyDetail.vue'),
      // },
      // {
      //   path: '/settlement/supermarkets',
      //   meta: { title: '商超清分', isMenu: true },
      // },
      // {
      //   path: '/settlement/supermarkets/shopDetail',
      //   meta: { title: '商超明细', isMenu: true },
      //   component: () => import('../views/settlement/shopDetail.vue'),
      // },
      // {
      //   path: '/settlement/detail/shopDetailVo',
      //   meta: { title: '商超明细详情' },
      //   component: () => import('../views/settlement/detail/shopDetailVo.vue'),
      // },
      // {
      //   path: '/settlement/supermarkets/supermarketsWithdrawal',
      //   meta: { title: '提现申请', isMenu: true },
      //   component: () => import('../views/settlement/supermarketsWithdrawal.vue'),
      // },
      // {
      //   path: '/settlement/detail/supermarketsDetail',
      //   meta: { title: '查看申请' },
      //   component: () => import('../views/settlement/detail/supermarketsDetail.vue'),
      // },
    ],
  },
]
// 供应商管理
export default [
  {
    path: "/supplier",
    meta: { title: "供应商管理" },
    component: () => import("../views/supplier/index.vue"),
    children: [
      {
        path: "/supplier/list",
        meta: { title: "供应商列表", isMenu: true },
        component: () => import("../views/supplier/list.vue"),
      },
      {
        path: "/supplier/detail/supplierDetail",
        meta: { title: "供应商详情" },
        component: () => import("../views/supplier/detail/supplierDetail.vue"),
      },
      {
        path: "/supplier/detail/supplyList",
        meta: { title: "供货关系" },
        component: () => import("../views/supplier/detail/supplyList.vue"),
      },
      {
        path: "/supplier/detail/shopList",
        meta: { title: "查看商超" },
        component: () => import("../views/supplier/detail/shopList.vue"),
      },
    ],
  },
]
